// CarouselComponent.jsx
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // import styles

// Import your images
import lab from "../images/lab.jpeg"
import lab2 from "../images/lab2.jpg"
import lab3 from "../images/lab3.jpg"

const CarouselComponent = () => {
  return (
    <div className="carousel-wrapper">
      <Carousel
        showThumbs={false}
        showStatus={false}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <button type="button" onClick={onClickHandler} className="custom-arrow custom-arrow-prev">
              &#8249;
            </button>
          )
        }
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && (
            <button type="button" onClick={onClickHandler} className="custom-arrow custom-arrow-next">
              &#8250;
            </button>
          )
        }
      >
        <div>
          <img className="aboutimg" src={lab} alt="Lab 1" />
        </div>
        <div>
          <img className="aboutimg" src={lab2} alt="Lab 2" />
        </div>
        <div>
          <img className="aboutimg" src={lab3} alt="Lab 3" />
        </div>
        
      </Carousel>
    </div>
  );
};

export default CarouselComponent;
