import React from 'react'
import Back from "../components/Back"
import Heading from "../components/Heading"
import img from "../images/design.jpg"
import design from "../images/design2.jpg"
import design3 from "../images/design3.jpg"
function print3d() {
  return (
    <div className='edc'>
    <Back name='Engineering Design and Calibaration' title='Engineering Design' cover={img} />
   
    <div className="Contents">
    <Heading title='3-D Scanning Services' subtitle='' />
    <p>
  <br />
  
At QE Technical Solutions, we provide cutting-edge 3D printing services designed to meet the unique needs of a variety of industries. Our advanced 3D printing technology delivers high-quality, precise, and efficient solutions for prototyping, manufacturing, and product development.
</p>
<img  className='aboutimg' src={design} alt='' />
<ul className='roundedlist'>


<Heading title="Our Services Include:"/>
<li><b>Rapid Prototyping:</b>  Quickly produce accurate prototypes to test form, fit, and function, reducing time to market.</li>
<li><b>Custom Manufacturing: </b> Create bespoke parts and components tailored to your specific requirements.</li>
<li><b>Small Batch Production:  </b>Efficiently produce limited quantities of high-quality parts and products</li>
<li><b>Tooling & Fixtures:</b> Manufacture custom jigs, fixtures, and tooling to improve manufacturing processes and product quality.</li>
<li><b>Medical Models:</b>  Print precise anatomical models for surgical planning, medical training, and device testing.</li>
<li><b>Artistic and Decorative Objects:</b> Produce complex and intricate designs for artistic projects, decorations, and custom art pieces.</li>

</ul>
<img  className='aboutimg' src={design3} alt='' />
<Heading title="Why Choose QE Technical Solutions?"/>
<ul className="roundedlist">
<li><b>Precision:</b> Our cutting-edge 3D scanning technology delivers unparalleled accuracy and detail.</li>
<li><b>Expertise:</b> Our team of experienced professionals ensures reliable and high-quality results.</li>
<li><b>Flexibility</b>: We cater to a wide range of industries, providing customized solutions to meet your specific needs.</li>
<li><b>Innovation</b>: We leverage the latest advancements in 3D scanning to stay ahead of industry trends and deliver superior services.</li>
</ul>
Partner with QE Technical Solutions for your 3D scanning needs and experience the benefits of precise, efficient, and innovative solutions tailored to your industry.














    </div>
  </div>

  )
}

export default print3d