//GetStarted.jsx
import styles from "./styles";
import arrowUp from "../images/homepage_slider_1-1.png";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
const bg_blue_gradient =
  "bg-gradient-to-br from-teal-100 via-teal-200 to-teal-500";
const text_gradient =
  "bg-gradient-to-br from-teal-100 via-teal-200 to-teal-500 text-transparent bg-clip-text";
const GetStarted = () => (
  <Link className="getstarted" to="/Contact">
    {" "}
    {/* Specify the URL of the page you want to navigate to */}
    <div
      className={`${styles.flexCenter} w-[140px] sm:w-[140px] h-[140px] sm:h-[140px] rounded-full ${bg_blue_gradient} p-[2px] cursor-pointer`}
    >
      <div
        className={`${styles.flexCenter} flex-col bg-primary w-[100%] h-[100%] rounded-full`}
      >
        <div className={`${styles.flexStart} flex-row`}>
          <p className="font-poppins font-medium text-[1.5vw] sm:text-[1.5vw] leading-[23.4px]">
            <span className={text_gradient}>Get</span>
          </p>
          <img
            src={arrowUp}
            alt="arrow-up"
            className="w-[3vw] h-[1vw] object-contain"
          />
        </div>
        <p className="font-poppins font-medium text-[1.5vw] sm:text-[1.5vw] leading-[23.4px]">
          <span className={text_gradient}>Started</span>
        </p>
      </div>
    </div>
  </Link>
);
export default GetStarted;
