import React from "react";

import styles from "../components/styles";
import CardsGridmain from "./CardsGridmain";
import Heading from "../components/Heading";
import HeroDisplay from "../components/HeroDisplay";
function Home() {
    return (
      <>
        <div className={`bg-primary ${styles.flexStart}`}>
        <div className={`${styles.boxWidth}`}>
          <HeroDisplay/>
          
        </div>
        
      </div>
      <br/ >
      <br/ >
      <div className="Contentsh">
      <p>At QE Technical Solutions, we pride ourselves on being the perfect synergy of advanced 3D services and precision calibration laboratory expertise. Our extensive suite of 3D solutions—including scanning, printing, and designing—is enhanced by our state-of-the-art calibration services. This unique combination ensures the highest standards of accuracy, reliability, and innovation across a wide range of industries.</p>

<p>Our team of skilled professionals leverages the latest technology to deliver customized solutions that meet the specific needs of our clients. From creating detailed prototypes and accurate digital models to ensuring precise calibration of instruments and equipment, QE Technical Solutions is dedicated to excellence in every project.</p>

<p>Whether you are in automotive, aerospace, architecture, manufacturing, healthcare, or any other sector, our commitment to quality and precision makes us your ideal partner for all your 3D and calibration needs. Experience the exceptional service and innovative solutions that set QE Technical Solutions apart.</p></div>
      <Heading title='Our Main Services' subtitle='Check out what QETS offers to you!' />
      <CardsGridmain/>

      </>
    );
}

export default Home;