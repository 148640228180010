import React, { useState } from "react";
import { Nav } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { Link } from "react-router-dom";
import { HiOutlineMenu, HiOutlineX } from "react-icons/hi"; // Import menu icons

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="Navbar bg-white shadow-md sticky top-0 w-full z-50">
      <div className="flex justify-between items-center px-6 py-4">
        <Link to="/">
          <img className="logo h-16 w-16" src="logo.jpg" alt="logo" />
        </Link>
        <div className="block lg:hidden">
          <button
            onClick={toggleMenu}
            className="text-purple-700 bg-white p-2 rounded focus:outline-none"
          >
            {isOpen ? (
              <HiOutlineX size={30} className="text-purple-700" />
            ) : (
              <HiOutlineMenu size={30} className="text-purple-700" />
            )}
          </button>
        </div>
        <div
          className={`navmenu lg:flex ${
            isOpen ? "block" : "hidden"
          } w-full lg:w-auto`}
        >
          <Nav className="flex flex-col lg:flex-row lg:items-center">
            <Nav.Item>
              <Link to="/About" className="navlink">
                About Us
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link to="/Customer" className="navlink">
                Customer Portal
              </Link>
            </Nav.Item>
            <Nav.Menu title="Calibration Services">
              <Link to="/Pressure" className="navlink">
                <Nav.Item>Pressure</Nav.Item>
              </Link>
              <Link to="/Thermal" className="navlink">
                <Nav.Item>Thermal</Nav.Item>
              </Link>
              <Link to="/Flow" className="navlink">
                <Nav.Item>Flow</Nav.Item>
              </Link>
              <Link to="/Electrotechnical" className="navlink">
                <Nav.Item>Electrotechnical</Nav.Item>
              </Link>
            </Nav.Menu>
            <Nav.Menu
              title={
                <Link to="/Edc" className="navlink">
                  Engineering Design and Consulting
                </Link>
              }
            >
              <Link to="/scan3d" className="navlink">
                <Nav.Item>3D Scanning</Nav.Item>
              </Link>
              <Link to="/design3d" className="navlink">
                <Nav.Item>3D Designing</Nav.Item>
              </Link>
              <Link to="/print3d" className="navlink">
                <Nav.Item>3D Printing</Nav.Item>
              </Link>
            </Nav.Menu>
            <Nav.Menu title="Validation Services">
              <Link to="/Csv" className="navlink">
                <Nav.Item>CSV</Nav.Item>
              </Link>
              <Link to="/Cqv" className="navlink">
                <Nav.Item>CQV</Nav.Item>
              </Link>
              <Link to="/Thermal2" className="navlink">
                <Nav.Item>Thermal</Nav.Item>
              </Link>
            </Nav.Menu>
            <Nav.Item>
              <Link to="/Productsales" className="navlink">
                Product Sales
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link to="/Industries" className="navlink">
                Industries
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link to="/Contact" className="navlink">
                Contact Us
              </Link>
            </Nav.Item>
          </Nav>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
