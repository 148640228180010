// HeroDisplay.jsx
import React, { useState, useEffect } from 'react';
import Hero from './Hero';
import Hero2 from './Hero2';
import '../App.css'; // Import your CSS for styling

const HeroDisplay = () => {
  const [showFirstHero, setShowFirstHero] = useState(true);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (!isHovered) {
      const interval = setInterval(() => {
        setShowFirstHero(prevShowFirstHero => !prevShowFirstHero);
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [isHovered]);

  return (
    <div
      className="hero-container"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {showFirstHero ? <Hero /> : <Hero2 />}
    </div>
  );
};

export default HeroDisplay;
