
export const footerdata = [
  {
    title: "COMPANY",
    text: [
      { list: "About", link: "/About" },
      { list: "Customer", link: "/Customer" },
      { list: "Industries", link: "/Industries" },
      { list: "Product Sales", link: "/Productsales" },
      { list: "Contact", link: "/Contact" },
  
    ],
  },
];
