import React from "react";
import Back from "../components/Back";
import Heading from "../components/Heading";
import img from "../images/design.jpg";
import design from "../images/scan3d.jpg";
import design2 from "../images/3d-scanner.jpg";
function scan3d() {
  return (
    <div className="edc">
      <Back
        name="Engineering Design and Calibaration"
        title="Engineering Design"
        cover={img}
      />

      <div className="Contents">
        <Heading title="3-D Scanning Services" subtitle="" />
        <p>
          <br />
          At QE Technical Solutions, we offer state-of-the-art 3D scanning
          services tailored to meet the diverse needs of various industries. Our
          advanced 3D scanning technology provides precise, high-resolution
          digital representations of physical objects, enabling improved
          accuracy and efficiency in your projects.
        </p>
        <img className="aboutimg" src={design2} alt="" />
        <Heading title="Our Services Include:-" />
        <ul className="roundedlist">
          <li>
            <b>Reverse Engineering: </b>Convert physical objects into precise
            digital models for redesign and replication.
          </li>
          <li>
            <b>Quality Control & Inspection:</b> Perform detailed inspections to
            identify deviations and ensure adherence to specifications.
          </li>
          <li>
            <b>As-Built Documentation:</b> Capture accurate data of existing
            structures for renovation, retrofitting, or historical preservation.
          </li>
          <li>
            <b>Product Design & Development: </b>Accelerate the design process
            with accurate 3D models for prototyping and manufacturing.
          </li>
          <li>
            <b>Virtual Simulation: </b>Create realistic simulations for testing
            and analysis before physical production.
          </li>
        </ul>
        <img className="aboutimg" src={design} alt="" />
        <Heading title="Why Choose QE Technical Solutions?" />
        <ul className="roundedlist">
          <li>
            <b>Precision:</b> Our cutting-edge 3D scanning technology delivers
            unparalleled accuracy and detail.
          </li>
          <li>
            <b>Expertise:</b> Our team of experienced professionals ensures
            reliable and high-quality results.
          </li>
          <li>
            <b>Flexibility</b>: We cater to a wide range of industries,
            providing customized solutions to meet your specific needs.
          </li>
          <li>
            <b>Innovation</b>: We leverage the latest advancements in 3D
            scanning to stay ahead of industry trends and deliver superior
            services.
          </li>
        </ul>
        
        
        
        <p>
          Partner with QE Technical Solutions for your 3D scanning needs and
          experience the benefits of precise, efficient, and innovative
          solutions tailored to your industry.
        </p>
      </div>
    </div>
  );
}

export default scan3d;
