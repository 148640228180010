import React from "react";
import Back from "../components/Back";
import Heading from "../components/Heading";
import img from "../images/design3.jpg";
import design from "../images/design3d.jpg";
import design2 from "../images/design4.jpg";

function design3d() {
  return (
    <div className="edc">
      <Back
        name="Engineering Design and Calibaration"
        title="Engineering Design"
        cover={img}
      />

      <div className="Contents">
        <Heading title="3-D Designing Services" subtitle="" />
        <p>
          <br />
          At QE Technical Solutions, we offer premier 3D designing services tailored to meet the intricate requirements of various industries. Our expert team of designers and engineers utilizes the latest 3D design software to create accurate, detailed, and functional designs, bringing your ideas to life with precision and creativity.
        </p>
        <Heading title="Tools we use:-"/>
        <ul className="roundedlist">
  <li>Auto Cad and other CAD tools</li>
  <li>Hexagon CADWorkx</li>
  <li>Hexagon PDS & S3D</li>
</ul>
        <img className="aboutimg" src={design} alt="" />
        <Heading title="Our Services Include:-" />
        <ul className="roundedlist">
        
          <li>
            <b>Conceptual Design: </b> Transform your ideas into detailed 3D models, visualizing concepts and exploring design possibilities.
          </li>
          <li>
            <b>Product Design:</b>  Develop comprehensive designs for new products, from initial concept to final prototype.
          </li>
          <li>
            <b>CAD Modeling:</b> Create precise 3D CAD models for manufacturing, assembly, and analysis, ensuring accuracy and functionality.
          </li>
          <li>
            <b>Reverse Engineering:</b> Generate accurate 3D models from existing physical objects, enabling redesign and replication.
          </li>
          <li>
            <b>Simulation & Analysis:</b> Perform virtual testing and analysis on 3D models to ensure performance, safety, and reliability.
          </li>
          <li>
          <b>Rendering & Visualization:</b> Produce high-quality renderings and visualizations for presentations, marketing, and design validation.</li>
        </ul>
        <img className="aboutimg" src={design2} alt="" />
        <Heading title="Why Choose QE Technical Solutions?" />
        <ul className="roundedlist">
            
          <li>
            <b>Precision:</b> Our cutting-edge 3D scanning technology delivers
            unparalleled accuracy and detail.
          </li>
          <li>
            <b>Expertise:</b> Our team of experienced professionals ensures
            reliable and high-quality results.
          </li>
          <li>
            <b>Flexibility</b>: We cater to a wide range of industries,
            providing customized solutions to meet your specific needs.
          </li>
          <li>
            <b>Innovation</b>: We leverage the latest advancements in 3D
            scanning to stay ahead of industry trends and deliver superior
            services.
          </li>
        </ul>
        
       
        <p>
          Partner with QE Technical Solutions for your 3D scanning needs and
          experience the benefits of precise, efficient, and innovative
          solutions tailored to your industry.
        </p>
      </div>
    </div>
  );
}

export default design3d;
