import React from "react"
import Back from "../components/Back" 
import Heading from "../components/Heading"
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import img from "../images/img6.jpeg"

import "./about.css"
import CarouselComponent from "../components/CarouselComponent"

const About = () => {
  return (
    <>
      <div className='Edc'>
        <Back name='About Us' title='About Us - Who We Are?' cover={img} />
        <div className='containerflexmtop'>
      \
            <div className="Contents">  
            <Heading title='Who are we?' subtitle='' />
    
  
            <p>Companies with the most stringent quality requirements trust QE TECHNICAL SOLUTIONS to meet their calibration needs. QE TECHNICAL SOLUTIONS provides calibrations for a broad array of disciplines and instruments, allowing you to fulfill your calibration needs from a single, reliable vendor.</p>
            <p>The Laboratory is very well  equipped with state-of-the-art,  world-class, imported, and  indigenous Master instruments and  Reference Standards, Which are  employed for providing precise and  accurate calibration services to  prestigious clients.</p>
            <p>We specialize in providing detailed engineering services for long-term turnkey projects. Our comprehensive EPCM (Engineering, Procurement, Construction, and Management) services encompass Project Management, Feasibility Studies, Conceptual & Basic Engineering, Detailed Design, Procurement, Construction Management, Commissioning & Start-up, and Operations & Maintenance.</p>

<p>As experts in 3D modeling, we offer a full range of multidisciplinary plant modeling services. Our team of skilled CAD professionals and design engineers work closely with EPCs, engineering consultancies, and OEMs to deliver top-quality 3D modeling services. With extensive on-site experience, we create digital models that serve as real-life walkthroughs, providing a thorough understanding of the facility before construction begins.</p>
<ul className="roundedlist">
                <li>Pressure and Vacuum Gauges</li>
                <li>Pressure Modules</li>
                <li>Digital Pressure Gauges</li>
                <li>Digital Manometers, Barometers, Valves, and Pressure Calibrators</li>
                <li>Analog Pressure and Test Gauges</li>
                <li>Pressure Sensors</li>
                <li>Process Gauges</li>
            </ul>     
            <CarouselComponent/>

            </div>
          </div>
     

      
          </div>
    
    </>
  )
}

export default About
